// import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { css } from "@emotion/core"; // https://github.com/gatsbyjs/gatsby/blob/master/examples/using-emotion/src/pages/index.js
// import styled from "@emotion/styled";
import { Flex, Box } from "@rebass/grid/emotion"; //https://github.com/rebassjs/grid
import { MainWrapper, PaddedMobile } from "../utils/styles";
import MovLink from "./movlink";
import { rhythm } from "../utils/typography";

const footerTitle = css`
  font-size: 1.4em;
  font-weight: 300;
  color: white;
  a {
    color: white;
    text-decoration: none;
  }
`;

const menuList = css`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const menuListItem = css`
  /* float: left; */
  /* display: inline; */
  margin: 0;
  a {
    color: white;
    /* text-align: center; */
    /* padding: 16px; */
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  a:active {
    color: white;
  }
  font-size: 114%;
  padding: 10px 0 4px 0;
  @media (min-width: 40em) {
    font-size: 120%;
    padding: 10px 0 4px 0;
  }
`;

const Footer = ({ siteTitle }) => (
  <footer
    css={css`
      background: #39394f;
      margin-top: ${rhythm(1)};
    `}
  >
    <MainWrapper>
      <Flex flexWrap="wrap">
        <Box
          width={1}
          pt={4}
          css={css`
            a {
              /* text-decoration: none; */
            }
          `}
        >
          <h2 css={[footerTitle, PaddedMobile]}>
            <MovLink to="/">MA User Experience Design</MovLink>
          </h2>
        </Box>
        <Box width={1} pb={4}>
          <ul css={[menuList, PaddedMobile]}>
            <li css={menuListItem}>
              <MovLink to="/projects">Projects</MovLink>
            </li>
            <li css={menuListItem}>
              <MovLink to="/students">Students</MovLink>
            </li>
            <li css={menuListItem}>
              <MovLink to="/competencies">Competencies</MovLink>
            </li>
            <li css={menuListItem}>
              <MovLink to="/about">About</MovLink>
            </li>
          </ul>
        </Box>
      </Flex>
    </MainWrapper>
  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
