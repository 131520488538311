import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { css } from "@emotion/core"; // https://github.com/gatsbyjs/gatsby/blob/master/examples/using-emotion/src/pages/index.js

const LinkStyle = css`

`

const MovLink = ({ to, direction = "up", children }) => {
  return (
    <AniLink
    fade
      // bg="#1E1E1E"
      //background: bg-color bg-image position/bg-size bg-repeat bg-origin bg-clip bg-attachment initial|inherit;
      // bg="
      // #1e1e1e            
      // url(/swipe.png)
      // center/cover
      // no-repeat
      // padding-box
      // content-box
      // /* fixed */
      // "
      // direction={direction}
      to={to}
      activeStyle={{ color: "gold" }}
      duration={0.6}
      css={LinkStyle}
    >
      {children}
    </AniLink>
  );
};

export default MovLink;
