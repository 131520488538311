import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Header from "../components/header";
// import Navbar from "../components/navbar"
import Footer from "../components/footer";
import { css } from "@emotion/core";
// import styled from "@emotion/styled";
import { GlobalStyle, MainWrapper } from "../utils/styles";
// import './all.sass'
// Using css with a template literal
const main = css`
  margin-top: 0px;
`;

const Layout = ({ children, location }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <GlobalStyle />
          <Helmet
            title={`MA User Experience Design`}
            meta={[
              {
                name: "description",
                content:
                  "MA User Experience Design at LCC is a design-led, professionally focused program that equips students with the specialist skills to conceive, prototype and realise human experiences in an interactive digital context.",
              },
            ]}
            // meta={[
            //   {
            //     name: "google-site-verification",
            //     content: "hgPazxh6tOPBOh_tVjfC_e38uYY1rpEFS65AdoMWSCE",
            //   },
            // ]}
          />
          <Header
            siteTitle={data.site.siteMetadata.title}
            location={location}
          />
          <MainWrapper>
            <main css={main}>{children}</main>
          </MainWrapper>
          <Footer siteTitle={data.site.siteMetadata.title} />
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
